import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  styles,
  Heading,
  Padding,
  PinkDelimiter,
  Text,
} from "../../common/styles"
import styled from "@emotion/styled"
import { StaticQuery, graphql } from "gatsby"
import _ from "lodash"
import Masonry from "react-masonry-component"
import Img from "gatsby-image"

const Pressebilder = () => {
  return (
    <Layout>
      <SEO
        title="Pressebilder"
        keywords={["Pressebilder", "Patricia on Ice"]}
      />
      <Wrapper>
        <Padding value="2vw" axis="y" />
        <Heading color={styles.colors.blue.dark}>Pressebilder</Heading>
        <Padding value="2vw" axis="y" />
        <PinkDelimiter />
        <Padding value="2vw" axis="y" />
        <StyledText>
          Für Pressebilder gelten rechtliche Hinweise. <br /> Die Bilder dürfen
          nur mit ausdrücklicher Genehmigung heruntergeladen werden.
        </StyledText>
        <Padding value="4vw" axis="y" />
      </Wrapper>
      <StaticQuery
        query={graphql`
          {
            allWordpressWpMedia(
              filter: {
                categories: { elemMatch: { name: { eq: "Pressebilder" } } }
              }
            ) {
              nodes {
                categories {
                  name
                }
                localFile {
                  childImageSharp {
                    fixed(quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <MasonWrapper>
              <Masonry
                options={{ isFitWidth: true, transitionDuration: 0 }}
                className={{ margin: "0 auto" }}
                children={_.map(data.allWordpressWpMedia.nodes, (node, idx) => (
                  <StyledImg
                    key={idx}
                    fixed={node.localFile.childImageSharp.fixed}
                  />
                ))}
              />
            </MasonWrapper>
          )
        }}
      />
      <Padding value="5vw" axis="y" />
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
`

const MasonWrapper = styled.div`
  max-width: 80vw;
  margin: 0 auto;
  * {
    max-width: 80vw;
  }
`

const StyledImg = styled(Img)`
  border: 0.5vw solid white;
`

const StyledText = styled(Text)`
  align-self: flex-start;
`

export default Pressebilder
